var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    { attrs: { name: "fade" } },
    [
      _c("FixedPopup", [
        _c("div", { staticClass: "selectFriendsDialog" }, [
          _c("div", { staticClass: "selectFriendsDialog__title" }, [
            _vm._v("選擇星盤好友")
          ]),
          _c("div", { staticClass: "selectFriendsDialog__filter" }, [
            _c("label", [
              _c("img", {
                staticClass: "serachIcon",
                attrs: { src: require("@/assets/占星小鋪/search.svg"), alt: "" }
              }),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchKey_temp,
                    expression: "searchKey_temp"
                  }
                ],
                attrs: { type: "text", placeholder: "搜尋星盤名稱" },
                domProps: { value: _vm.searchKey_temp },
                on: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.filterHandler.apply(null, arguments)
                  },
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.searchKey_temp = $event.target.value
                  }
                }
              })
            ]),
            _c(
              "button",
              { staticClass: "filter_btn", on: { click: _vm.filterHandler } },
              [_vm._v("搜尋")]
            )
          ]),
          _vm.list_filter.length > 0
            ? _c(
                "div",
                { staticClass: "selectFriendsDialog__list" },
                _vm._l(_vm.list_filter, function(item) {
                  return _c(
                    "div",
                    {
                      key: item.AstrolabeFileId,
                      staticClass: "selectAstrolabeItem"
                    },
                    [
                      _c("div", { staticClass: "selectAstrolabeItem__pic" }, [
                        _c("img", {
                          attrs: { src: item.ProfilePhotoSrc, alt: "" }
                        })
                      ]),
                      _c("div", { staticClass: "selectAstrolabeItem__info" }, [
                        _c(
                          "div",
                          { staticClass: "selectAstrolabeItem__info__name" },
                          [_vm._v(_vm._s(item.Name))]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "selectAstrolabeItem__info__birthday"
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(item.BirthDate) +
                                "\n            "
                            )
                          ]
                        )
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "selectAstrolabeItem__btn",
                          class: { active: item.is_checked },
                          on: {
                            click: function($event) {
                              return _vm.selectHandler(item)
                            }
                          }
                        },
                        [
                          item.is_checked
                            ? _c("img", {
                                attrs: {
                                  src: require("@/assets/占星小鋪/selectActive.svg")
                                }
                              })
                            : _c("img", {
                                attrs: {
                                  src: require("@/assets/占星小鋪/select.svg"),
                                  alt: ""
                                }
                              })
                        ]
                      )
                    ]
                  )
                }),
                0
              )
            : _c("div", { staticClass: "selectFriendsDialog__empty" }, [
                _c("div", [_vm._v("無符合條件的星盤")])
              ]),
          _c("div", { staticClass: "selectFriendsDialog__closeBtn" }, [
            _c("img", {
              attrs: { src: require("@/assets/月亮日記/close.svg"), alt: "" },
              on: {
                click: function($event) {
                  return _vm.cancel()
                }
              }
            })
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }