<template>
  <div class="wrap" id="addDBL">
    <div class="header">
      <h2 class="st_title">
        <img src="@/assets/占星小鋪/friend.svg" alt="" />
        新增合盤
      </h2>
      <div class="filterBar">
        <label>
          <img
            class="serachIcon"
            src="../../assets/占星小鋪/search.svg"
            alt=""
          />
          <input
            type="text"
            placeholder="搜尋好友名稱"
            v-model="filterKey"
            @keyup.enter="filterHandler"
          />
        </label>
        <button class="filter_btn" @click="filterHandler">搜尋</button>
        <div class="addFriends_btn" @click="toAddFriend()">
          <img src="../../assets/占星小鋪/add friend.svg" alt="" />
        </div>
      </div>
    </div>

    <!-- 主要選擇面板 -->
    <div class="addDBL__main">
      <h3>選擇合盤類型</h3>
      <div class="addDBL__main__typeGroup">
        <label
          class="addDBL__main__typeGroup__item"
          :class="{ active: typeSelect.workplace }"
        >
          <div class="checkbox-wrap">
            <input type="checkbox" v-model="typeSelect.workplace" />
            <span class="checkmark"></span>
          </div>
          <div class="addDBL__main__typeGroup__item__pic">
            <img src="@/assets/雙人合盤/職場.svg" alt="" />
          </div>
          <div class="addDBL__main__typeGroup__title">職場</div>
        </label>
        <label
          class="addDBL__main__typeGroup__item"
          :class="{ active: typeSelect.couple }"
        >
          <div class="checkbox-wrap">
            <input type="checkbox" v-model="typeSelect.couple" />
            <span class="checkmark"></span>
          </div>
          <div class="addDBL__main__typeGroup__item__pic">
            <img src="@/assets/雙人合盤/情侶.svg" alt="" />
          </div>
          <div class="addDBL__main__typeGroup__title">感情</div>
        </label>
        <label
          class="addDBL__main__typeGroup__item"
          :class="{ active: typeSelect.parentChild }"
        >
          <div class="checkbox-wrap">
            <input type="checkbox" v-model="typeSelect.parentChild" />
            <span class="checkmark"></span>
          </div>
          <div class="addDBL__main__typeGroup__item__pic">
            <img src="@/assets/雙人合盤/親子.svg" alt="" />
          </div>
          <div class="addDBL__main__typeGroup__title">親子</div>
        </label>
      </div>
      <div class="addDBL__main__divi">
        <span class="addDBL__main__divi__line"></span>
        <div class="addDBL__main__divi__content">選擇兩位好友</div>
        <span class="addDBL__main__divi__line"></span>
      </div>
      <div class="select__show">
        <div class="select__show__item" @click="showSelectFriendsDialog = true">
          <div v-if="select_A" class="select__show__item__text">
            {{ select_A.Name }}
          </div>
          <div v-else class="select__show__item__text">選擇好友</div>
          <img src="@/assets/icon_downward.svg" />
        </div>
        <div class="select__show__pic">
          <img src="@/assets/雙人合盤/好友分隔.svg" alt="" />
        </div>
        <div class="select__show__item" @click="showSelectFriendsDialog = true">
          <div v-if="select_B" class="select__show__item__text">
            {{ select_B.Name }}
          </div>
          <div v-else class="select__show__item__text">選擇好友</div>
          <img src="@/assets/icon_downward.svg" />
        </div>
      </div>
      <div
        @click="next()"
        class="lockedBtn"
        :class="{
          disabled:
            (!typeSelect.couple &&
              !typeSelect.parentChild &&
              !typeSelect.workplace) ||
            !select_A ||
            !select_B,
        }"
      >
        <img src="../../assets/雙人合盤/locked.svg" class="mr_3" />
        點此解鎖
      </div>
    </div>
    <!--你與你的好友列表 -->
    <div class="addDBLFriends">
      <h2>你與你的好友列表</h2>
      <div class="addDBLFriends__list">
        <div
          class="addDBLFriends__list__item"
          v-for="item in List_filter"
          :key="item.AstrolabeFileId"
        >
          <FriendCardDBL
            :is_checked="item.is_checked"
            :data="item"
            @toSelect="selectHandler(item, $event)"
          >
          </FriendCardDBL>
        </div>
      </div>
    </div>

    <!-- 好友列表dialog -->
    <SelectFriendsDialog
      v-show="showSelectFriendsDialog"
      :friendsList="List_all"
      @toSelect="({ item, is_checked }) => selectHandler(item, is_checked)"
      @cancel="showSelectFriendsDialog = false"
    ></SelectFriendsDialog>
  </div>
</template>

<script>
import FriendCardDBL from "../../components/雙人合盤/FriendCardDBL.vue";
import SelectFriendsDialog from "../../components/雙人合盤/SelectFriendsDialog.vue";
export default {
  components: {
    FriendCardDBL,
    SelectFriendsDialog,
  },
  data() {
    return {
      List_all: [],
      List_filter: [],
      filterKey: "",
      select_A: null,
      select_B: null,
      typeSelect: {
        couple: false,
        parentChild: false,
        workplace: false,
      },
      showSelectFriendsDialog: false,
    };
  },
  created() {
    this.$API.Get_MemberInfo().then((data) => {
      window.localStorage["loginIndex"] = data.Data.MemberId;
      this.$store.commit("set_userData", data.Data);
      this.init();
    });
  },
  computed: {
    productInfoSynastry() {
      return this.$store.state.productList.find(
        (item) => item.Code == "Synastry"
      );
    },
  },
  methods: {
    init() {
      this.List_all = this.$store.state.userData.AstrolabeFriendLists.map(
        (item) => {
          item.is_checked = false;
          return JSON.parse(JSON.stringify(item));
        }
      ).sort((a, b) => {
        return a.ProfilePhotoSrc.split("/")[3].localeCompare(
          b.ProfilePhotoSrc.split("/")[3]
        );
      });
      this.List_filter = [...this.List_all];
    },
    filterHandler() {
      this.List_filter = this.List_all.filter(
        (item) =>
          item.Name.toUpperCase().indexOf(this.filterKey.toUpperCase()) > -1
      );
    },
    toAddFriend() {
      this.$router.push("/friend-create");
    },
    selectHandler(item, is_checked) {
      if (!is_checked) {
        if (!this.select_A) {
          this.select_A = item;
          item.is_checked = true;
        } else if (!this.select_B) {
          this.select_B = item;
          item.is_checked = true;
        }
      } else {
        //目前已經是選取中的狀態
        if (this.select_A == item) {
          item.is_checked = false;
          this.select_A = null;
        } else if (this.select_B == item) {
          item.is_checked = false;
          this.select_B = null;
        }
      }
      if(this.select_A && this.select_B){
        this.showSelectFriendsDialog = false;
      }
      this.$forceUpdate();
    },
    next() {
      let orderObj = this.$store.state.orderCreater;
      orderObj.ProductID = this.productInfoSynastry.ProductID;
      orderObj.Code = this.productInfoSynastry.Code;
      orderObj.SynastryDto = {
        AstrolabeFileId1: this.select_A.AstrolabeFileId,
        AstrolabeFileId2: this.select_B.AstrolabeFileId,
        Relationships: [],
      };

      orderObj.Quantity = 0;
      if (this.typeSelect.couple) {
        orderObj.Quantity++;
        orderObj.SynastryDto.Relationships.push("Couple");
      }
      if (this.typeSelect.parentChild) {
        orderObj.Quantity++;
        orderObj.SynastryDto.Relationships.push("Parent-child");
      }
      if (this.typeSelect.workplace) {
        orderObj.Quantity++;
        orderObj.SynastryDto.Relationships.push("Workplace");
      }
      console.log("orderObj", orderObj);
      this.$store.commit("updateOrderCreater", orderObj);
      this.$router.push("/order/checkInfo/");
    },
  },
  watch: {
    $route(to) {
      if (!to.query.mode) {
        this.$store.commit("setLoading", true);
        setTimeout(() => {
          this.init();
          this.$store.commit("setLoading", false);
        }, 1000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixin.scss";
@import "@/scss/toast.scss";
@import "@/scss/dialog.scss";
.wrap#addDBL {
  padding-top: 62px;
  width: 100%;
  max-width: 1160px;
  margin: 0 auto;
  min-height: 100vh;
  letter-spacing: 0.08em;
  @include pad {
    padding: 20px 23px 0;
  }
  .header {
    display: flex;
    align-items: center;
    @include pad {
      flex-direction: column;
      align-items: center;
    }
    h2.st_title {
      font-weight: 500;
      font-size: 40px;
      color: $theme-color-2;
      display: flex;
      align-items: center;
      margin-right: auto;
      line-height: 1;
      @include pad {
        display: none;
      }
      img {
        width: 54px;
        margin-right: 16px;
        @include pad {
          width: 30px;
          margin-right: 5px;
        }
      }
    }

    .filterBar {
      margin: 0 0 0;
      display: flex;
      justify-content: center;
      align-items: center;
      @include pad {
        margin-top: 24px;
      }
      input,
      input:focus {
        width: 300px;
        height: 36px;
        background: #f0f2f4;
        box-shadow: 2px 2px 4px #ffffff, inset 2px 2px 4px rgba(0, 0, 0, 0.15);
        border-radius: 25px;
        outline: none;
        padding: 8px 46px;
        @include pad {
          max-width: 179px;
          height: 36px;
        }
      }
      input::placeholder {
        font-size: 14px;
        line-height: 20px;
        color: #d9d9d9;
      }
      label {
        position: relative;
        .serachIcon {
          position: absolute;
          left: 20px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .filter_btn {
        @include btn-default(112px, 36px);
        border-radius: 100px;
        margin-left: 16px;
        @include pad {
          @include btn-default(72px, 36px);
        }
      }
      .addFriends_btn {
        @include btn-default(40px, 40px);
        border-radius: 50%;
        margin-left: 30px;
        @include pad {
          margin-left: 20px;
        }
        img {
          width: 90%;
        }
      }
    }
  }
  //主要設定面板
  .addDBL__main {
    max-width: 910px;
    background: #f0f2f4;
    box-shadow: -4px -4px 16px #ffffff, -4px -4px 10px #ffffff,
      4px 4px 25px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    padding: 44px 32px 52px;
    margin: 58px auto 72px;
    @include pad {
      margin: 44px auto 40px;
    }
    h3 {
      font-size: 16px;
      line-height: 28px;
      letter-spacing: 0.08em;
      text-align: center;
      color: #868686;
    }
    .addDBL__main__typeGroup {
      margin-top: 30px;
      margin-bottom: 56px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 44px;
      line-height: 1;
      @include mobile {
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 36px;
      }
      div {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &__item {
        width: 174px;
        height: 104px;
        border-radius: 40px;
        // box-shadow: 2.25px 2.25px 6.75px rgba(0, 0, 0, 0.25) , -1.13px -1.13px
        //   4.5px rgba(255, 255, 255, 1);
        box-shadow: -4px -4px 16px #ffffff, -4px -4px 10px #ffffff,
          4px 4px 25px rgba(0, 0, 0, 0.3);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        user-select: none;
        @include pad {
          width: 50%;
        }
        &.active {
          box-shadow: 2px 2px 4px #ffffff, inset 2px 2px 4px rgba(0, 0, 0, 0.15);
        }
        .checkbox-wrap {
          display: none; //隱藏checkbox
        }
        &__pic {
          width: 48px;
          padding-right: 8px;
          img {
            width: 48px;
          }
        }
      }
      &__title {
        font-size: 20px;
        color: #767676;
        @include mobile {
          font-size: 14px;
        }
      }
    }
    .addDBL__main__divi {
      font-size: 16px;
      line-height: 28px;
      margin: 0 auto;
      text-align: center;
      color: #868686;
      width: 100%;
      // background-color: #f00;
      position: relative;
      display: flex;
      align-items: center;
      &__content {
        width: 135px;
        @include pad {
          font-size: 12px;
        }
      }
      &__line {
        flex-grow: 1;
        height: 3px;
        background-color: #f0f2f4;
        border-radius: 3px;
        box-shadow: 3px 3px 4px #ffffff, inset 4px 4px 10px rgba(0, 0, 0, 0.08);
      }
    }
    .select__show {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 28px;
      margin: 16px auto 40px;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: 0.06em;
      color: #767676;
      &__item {
        height: 40px;
        width: 136px;
        border-radius: 50px;
        padding: 0 8px;

        box-shadow: -4px -4px 16px #ffffff, -4px -4px 10px #ffffff,
          4px 4px 25px rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-size: 18px;
        line-height: 20px;
        color: #767676;
        @include mobile {
          font-size: 14px;
        }
        &__text {
          width: calc(100% - 16px - 24px);
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          text-overflow: ellipsis;
          text-align: center;
          @include mobile {
            width: calc(100% - 16px - 20px);
          }
        }
        img {
          width: 24px;
          height: 24px;
          @include mobile {
            height: 20px;
            width: 20px;
          }
        }
      }
      &__pic {
        margin: 0 20px;
        width: 56px;
      }
    }
    .lockedBtn {
      @include btn-default(168px, 44px);
      margin: 0 auto;
      &.disabled {
        pointer-events: none;
        background-color: #9b9b9b;
      }
    }
  }

  .addDBLFriends {
    max-width: 1032px;
    margin: 0 auto;
    h2 {
      font-weight: 500;
      font-size: 28px;
      line-height: 58px;
      color: $theme-color-2;
      display: flex;
      align-items: center;
      margin-right: auto;
      @include pad {
        font-size: 16px;
      }
    }
    &__list {
      margin-top: 36px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      @include pad {
        justify-content: center;
      }
      &__item {
        width: 480px;
        margin-bottom: 60px;
        @include pad {
          margin-bottom: 36px;
        }
      }
    }
  }
}
.checkbox-wrap {
  margin-left: 20px;
  cursor: pointer;
  font-size: 20px;
  line-height: 1.5;
  letter-spacing: 1.6px;
  color: $secondary-color-3;
  position: relative;
  padding-left: 32px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* Hide the browser's default checkbox */
  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: -2px;
    transform: translateY(-50%);
    @include rect-s(20px, 20px, $theme-color-3, 3px);
    border: 1px solid #aaa;
    box-shadow: -1px -1px 4px #ffffff, 2px 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 5.5px;
      top: 1px;
      width: 7px;
      height: 13px;
      border: solid #fff;
      border-width: 0px 3px 3px 0;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
  input:checked ~ .checkmark {
    background-color: $theme-color-1;
    border: 1px solid $theme-color-1;
    &:after {
      display: block;
    }
  }

  @include pad {
    font-size: 14px;
    line-height: 1;
    letter-spacing: 0.56px;
    margin-left: 16px;
    padding-left: 28px;
    .checkmark {
      width: 16px;
      height: 16px;
      top: 0px;
      &:after {
        top: 0.5px;
        left: 4.5px;
        width: 6px;
        height: 10px;
        border-width: 0px 2px 2px 0;
      }
    }
  }
}
</style>
