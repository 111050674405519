<template>
  <div class="frendCardDBL">
    <div class="pic_box">
      <img :src="data.ProfilePhotoSrc" alt="" />
    </div>
    <div class="info_area">
      <div class="main_group">
        <div class="base">
          <div class="name">{{ data.Name }}</div>
          <div class="birthPlace mt-2">
            {{
              data.BirthDate
                ? data.BirthPlace
                : `${data.BirthCountry},${data.BirthCity}`
            }}
          </div>
          <div class="birthDay mb-2">
            <span v-if="data.BirthDate">
              <span class="mr-2">{{ data.BirthDate.split(" ")[0] }},</span>
              <span>{{ data.BirthDate.split(" ")[1] }} </span>
              <img
                v-if="data.IsDaylight"
                class="ml-1"
                src="@/assets/會員相關/sun.svg"
              />
            </span>
            <span v-else>
              <span class="mr-2">{{ data.BirthDay }}</span>
              <span>{{ data.BirthTime }}</span>
              <img
                v-if="data.IsDaylight"
                class="ml-3"
                src="@/assets/會員相關/sun.svg"
              />
            </span>
          </div>
        </div>
        <div class="control">
          <div class="btn_group">
            <div
              class="select_btn"
              :class="{ active: is_checked }"
              @click="toSelect()"
            >
              <img
                src="../../assets/占星小鋪/select.svg"
                alt=""
                v-show="!is_checked"
              />
              <img
                src="../../assets/占星小鋪/selectActive.svg"
                v-show="is_checked"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["data", "is_checked"],
  methods: {
    toSelect() {
      this.$emit("toSelect", this.is_checked || false);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixin.scss";
.frendCardDBL {
  width: 100%;
  height: auto;
  background: #f0f2f4;
  box-shadow: -4px -4px 16px #ffffff, -4px -4px 10px #ffffff,
    4px 4px 25px rgba(0, 0, 0, 0.3);
  border-radius: 25px 50px;
  padding: 25px;
  display: flex;
  position: relative;
  transition: 0.2s;
  user-select: none;
  @include mobile {
    width: auto;
    padding: 14px 16px;
    border-radius: 25px 35px;
    box-shadow: -2px -2px 4px #ffffff, -2px -2px 4px #ffffff,
      4px 4px 10px rgba(0, 0, 0, 0.2);
  }

  .pic_box {
    width: 80px;
    height: 80px;
    background: #f0f2f4;
    box-shadow: -1px -1px 4px #ffffff, -1px -1px 4px #ffffff,
      2px 2px 6px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    display: flex;
    margin-right: 40px;
    flex-shrink: 0;
    @include mobile {
      width: 60px;
      height: 60px;
      position: absolute;
      left: 16px;
      top: -8px;
    }
    img {
      width: 64px;
      margin: auto;
      @include mobile {
        width: 48px;
      }
    }
  }
  .info_area {
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.08em;
    color: #9b9b9b;
    @include mobile {
      margin-left: 75px;
    }
    .main_group {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      .base {
        .name {
          color: #767676;
          font-size: 20px;
          line-height: 36px;
          font-weight: 700;
          margin-bottom: 8px;
          @include mobile {
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 1px;
          }
        }
        .birthDay {
          @include mobile {
            font-size: 12px;
          }
        }
      }
      .control {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        .btn_group {
          display: flex;
          justify-content: flex-end;
        }
        .next {
          width: 136px;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          border-bottom: 2px solid #9b9b9b;
          color: #9b9b9b;
          font-size: 16px;
          line-height: 26px;
          cursor: pointer;
          position: relative;
          padding-right: 6px;
          img {
            margin-left: 11px;
          }
          &:after {
            content: "";
            position: absolute;
            top: 50%;
            right: 0;
            transform: translate(0, -50%);
            background: #d9d9d9;
            opacity: 0.6;
            width: 28px;
            height: 28px;
            border-radius: 50%;
          }
        }
        .select_btn {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          background: #f0f2f4;
          box-shadow: -1px -1px 4px #ffffff, -1px -1px 4px #ffffff,
            2px 2px 6px rgba(0, 0, 0, 0.25);
          display: flex;
          margin-left: 12px;
          cursor: pointer;
          &.active {
            background: #798da5;
            box-shadow: 2.25px 2.25px 4.5px #ffffff,
              inset 4.5px 4.5px 11.25px rgba(0, 0, 0, 0.08);
          }
          @include mobile {
            top: 18px;
            right: 20px;
            width: 28px;
            height: 28px;
            margin-left: 8px;
          }
          img {
            margin: auto;
            width: 20px;
          }
        }
      }
    }
  }
}
</style>
