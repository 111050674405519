var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap", attrs: { id: "addDBL" } },
    [
      _c("div", { staticClass: "header" }, [
        _vm._m(0),
        _c("div", { staticClass: "filterBar" }, [
          _c("label", [
            _c("img", {
              staticClass: "serachIcon",
              attrs: {
                src: require("../../assets/占星小鋪/search.svg"),
                alt: ""
              }
            }),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.filterKey,
                  expression: "filterKey"
                }
              ],
              attrs: { type: "text", placeholder: "搜尋好友名稱" },
              domProps: { value: _vm.filterKey },
              on: {
                keyup: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.filterHandler.apply(null, arguments)
                },
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.filterKey = $event.target.value
                }
              }
            })
          ]),
          _c(
            "button",
            { staticClass: "filter_btn", on: { click: _vm.filterHandler } },
            [_vm._v("搜尋")]
          ),
          _c(
            "div",
            {
              staticClass: "addFriends_btn",
              on: {
                click: function($event) {
                  return _vm.toAddFriend()
                }
              }
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/占星小鋪/add friend.svg"),
                  alt: ""
                }
              })
            ]
          )
        ])
      ]),
      _c("div", { staticClass: "addDBL__main" }, [
        _c("h3", [_vm._v("選擇合盤類型")]),
        _c("div", { staticClass: "addDBL__main__typeGroup" }, [
          _c(
            "label",
            {
              staticClass: "addDBL__main__typeGroup__item",
              class: { active: _vm.typeSelect.workplace }
            },
            [
              _c("div", { staticClass: "checkbox-wrap" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.typeSelect.workplace,
                      expression: "typeSelect.workplace"
                    }
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.typeSelect.workplace)
                      ? _vm._i(_vm.typeSelect.workplace, null) > -1
                      : _vm.typeSelect.workplace
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.typeSelect.workplace,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.typeSelect,
                              "workplace",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.typeSelect,
                              "workplace",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.typeSelect, "workplace", $$c)
                      }
                    }
                  }
                }),
                _c("span", { staticClass: "checkmark" })
              ]),
              _vm._m(1),
              _c("div", { staticClass: "addDBL__main__typeGroup__title" }, [
                _vm._v("職場")
              ])
            ]
          ),
          _c(
            "label",
            {
              staticClass: "addDBL__main__typeGroup__item",
              class: { active: _vm.typeSelect.couple }
            },
            [
              _c("div", { staticClass: "checkbox-wrap" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.typeSelect.couple,
                      expression: "typeSelect.couple"
                    }
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.typeSelect.couple)
                      ? _vm._i(_vm.typeSelect.couple, null) > -1
                      : _vm.typeSelect.couple
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.typeSelect.couple,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.typeSelect,
                              "couple",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.typeSelect,
                              "couple",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.typeSelect, "couple", $$c)
                      }
                    }
                  }
                }),
                _c("span", { staticClass: "checkmark" })
              ]),
              _vm._m(2),
              _c("div", { staticClass: "addDBL__main__typeGroup__title" }, [
                _vm._v("感情")
              ])
            ]
          ),
          _c(
            "label",
            {
              staticClass: "addDBL__main__typeGroup__item",
              class: { active: _vm.typeSelect.parentChild }
            },
            [
              _c("div", { staticClass: "checkbox-wrap" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.typeSelect.parentChild,
                      expression: "typeSelect.parentChild"
                    }
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.typeSelect.parentChild)
                      ? _vm._i(_vm.typeSelect.parentChild, null) > -1
                      : _vm.typeSelect.parentChild
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.typeSelect.parentChild,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.typeSelect,
                              "parentChild",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.typeSelect,
                              "parentChild",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.typeSelect, "parentChild", $$c)
                      }
                    }
                  }
                }),
                _c("span", { staticClass: "checkmark" })
              ]),
              _vm._m(3),
              _c("div", { staticClass: "addDBL__main__typeGroup__title" }, [
                _vm._v("親子")
              ])
            ]
          )
        ]),
        _vm._m(4),
        _c("div", { staticClass: "select__show" }, [
          _c(
            "div",
            {
              staticClass: "select__show__item",
              on: {
                click: function($event) {
                  _vm.showSelectFriendsDialog = true
                }
              }
            },
            [
              _vm.select_A
                ? _c("div", { staticClass: "select__show__item__text" }, [
                    _vm._v(
                      "\n          " + _vm._s(_vm.select_A.Name) + "\n        "
                    )
                  ])
                : _c("div", { staticClass: "select__show__item__text" }, [
                    _vm._v("選擇好友")
                  ]),
              _c("img", {
                attrs: { src: require("@/assets/icon_downward.svg") }
              })
            ]
          ),
          _vm._m(5),
          _c(
            "div",
            {
              staticClass: "select__show__item",
              on: {
                click: function($event) {
                  _vm.showSelectFriendsDialog = true
                }
              }
            },
            [
              _vm.select_B
                ? _c("div", { staticClass: "select__show__item__text" }, [
                    _vm._v(
                      "\n          " + _vm._s(_vm.select_B.Name) + "\n        "
                    )
                  ])
                : _c("div", { staticClass: "select__show__item__text" }, [
                    _vm._v("選擇好友")
                  ]),
              _c("img", {
                attrs: { src: require("@/assets/icon_downward.svg") }
              })
            ]
          )
        ]),
        _c(
          "div",
          {
            staticClass: "lockedBtn",
            class: {
              disabled:
                (!_vm.typeSelect.couple &&
                  !_vm.typeSelect.parentChild &&
                  !_vm.typeSelect.workplace) ||
                !_vm.select_A ||
                !_vm.select_B
            },
            on: {
              click: function($event) {
                return _vm.next()
              }
            }
          },
          [
            _c("img", {
              staticClass: "mr_3",
              attrs: { src: require("../../assets/雙人合盤/locked.svg") }
            }),
            _vm._v("\n      點此解鎖\n    ")
          ]
        )
      ]),
      _c("div", { staticClass: "addDBLFriends" }, [
        _c("h2", [_vm._v("你與你的好友列表")]),
        _c(
          "div",
          { staticClass: "addDBLFriends__list" },
          _vm._l(_vm.List_filter, function(item) {
            return _c(
              "div",
              {
                key: item.AstrolabeFileId,
                staticClass: "addDBLFriends__list__item"
              },
              [
                _c("FriendCardDBL", {
                  attrs: { is_checked: item.is_checked, data: item },
                  on: {
                    toSelect: function($event) {
                      return _vm.selectHandler(item, $event)
                    }
                  }
                })
              ],
              1
            )
          }),
          0
        )
      ]),
      _c("SelectFriendsDialog", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showSelectFriendsDialog,
            expression: "showSelectFriendsDialog"
          }
        ],
        attrs: { friendsList: _vm.List_all },
        on: {
          toSelect: function(ref) {
            var item = ref.item
            var is_checked = ref.is_checked

            return _vm.selectHandler(item, is_checked)
          },
          cancel: function($event) {
            _vm.showSelectFriendsDialog = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", { staticClass: "st_title" }, [
      _c("img", {
        attrs: { src: require("@/assets/占星小鋪/friend.svg"), alt: "" }
      }),
      _vm._v("\n      新增合盤\n    ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "addDBL__main__typeGroup__item__pic" }, [
      _c("img", {
        attrs: { src: require("@/assets/雙人合盤/職場.svg"), alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "addDBL__main__typeGroup__item__pic" }, [
      _c("img", {
        attrs: { src: require("@/assets/雙人合盤/情侶.svg"), alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "addDBL__main__typeGroup__item__pic" }, [
      _c("img", {
        attrs: { src: require("@/assets/雙人合盤/親子.svg"), alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "addDBL__main__divi" }, [
      _c("span", { staticClass: "addDBL__main__divi__line" }),
      _c("div", { staticClass: "addDBL__main__divi__content" }, [
        _vm._v("選擇兩位好友")
      ]),
      _c("span", { staticClass: "addDBL__main__divi__line" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "select__show__pic" }, [
      _c("img", {
        attrs: { src: require("@/assets/雙人合盤/好友分隔.svg"), alt: "" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }