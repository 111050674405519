<template>
  <div class="wrap" id="buyDBL">
    <div class="header">
      <h2 class="st_title">
        <img src="@/assets/占星小鋪/friend.svg" alt="" />
        合盤設定
      </h2>
      <div class="header__addFriendBtn hidden-pad-down" @click="addFriend()">
        <img src="../../assets/占星小鋪/add friend.svg" alt="" />
      </div>
    </div>

    <!-- 選擇類型 -->
    <div class="buyDBL__main">
      <h3>選擇合盤類型</h3>
      <h4>每次購買只能買一種合盤類型喔</h4>
      <div class="buyDBL__main__typeGroup">
        <label
          class="buyDBL__main__typeGroup__item"
          :class="{ active: typeSelect == 'couple' }"
        >
          <div class="checkbox-wrap">
            <input
              type="radio"
              v-model="typeSelect"
              value="couple"
              name="typeSelect"
            />
            <span class="checkmark"></span>
          </div>
          <div class="buyDBL__main__typeGroup__item__pic">
            <img src="@/assets/雙人合盤/情侶.svg" alt="" />
          </div>
          <div class="buyDBL__main__typeGroup__title">感情</div>
        </label>

        <label
          class="buyDBL__main__typeGroup__item"
          :class="{ active: typeSelect == 'friend' }"
        >
          <div class="checkbox-wrap">
            <input
              type="radio"
              v-model="typeSelect"
              value="friend"
              name="typeSelect"
            />
            <span class="checkmark"></span>
          </div>
          <div class="buyDBL__main__typeGroup__item__pic">
            <img src="@/assets/雙人合盤/好友.svg" alt="" />
          </div>
          <div class="buyDBL__main__typeGroup__title">朋友</div>
        </label>
        <label
          class="buyDBL__main__typeGroup__item"
          :class="{ active: typeSelect == 'workplace' }"
        >
          <div class="checkbox-wrap">
            <input
              type="radio"
              v-model="typeSelect"
              value="workplace"
              name="typeSelect"
            />
            <span class="checkmark"></span>
          </div>
          <div class="buyDBL__main__typeGroup__item__pic">
            <img src="@/assets/雙人合盤/職場.svg" alt="" />
          </div>
          <div class="buyDBL__main__typeGroup__title">職場</div>
        </label>
        <label
          class="buyDBL__main__typeGroup__item"
          :class="{ active: typeSelect == 'parentChild' }"
        >
          <div class="checkbox-wrap">
            <input
              type="radio"
              v-model="typeSelect"
              value="parentChild"
              name="typeSelect"
            />
            <span class="checkmark"></span>
          </div>
          <div class="buyDBL__main__typeGroup__item__pic">
            <img src="@/assets/雙人合盤/親子.svg" alt="" />
          </div>
          <div class="buyDBL__main__typeGroup__title">親子</div>
        </label>
      </div>
    </div>

    <!-- 設定合盤組合 -->
    <div class="buyDBL__main">
      <h3>設定合盤組合</h3>
      <h4>您可以一次購買多個合盤組合喔</h4>
      <div class="select__show" v-for="(set, index) in buySet" :key="index">
        <SelectBar
          :items="selectFriendsList"
          :value="set.AstrolabeId1"
          @change="
            (astrolabeFileId) => {
              set.AstrolabeId1 = astrolabeFileId;
            }
          "
        ></SelectBar>
        <!-- <div class="select__show__item" @click="showSelectFriendsDialog = true">
          <div v-if="select_A" class="select__show__item__text">
            {{ select_A.Name }}
          </div>
          <div v-else class="select__show__item__text">請選擇</div>
          <img src="@/assets/icon_downward.svg" />
        </div> -->

        <div class="select__show__pic">
          <img src="@/assets/雙人合盤/好友分隔.svg" alt="" />
        </div>
        <SelectBar
          :items="selectFriendsList"
          :value="set.AstrolabeId2"
          @change="
            (astrolabeFileId) => {
              set.AstrolabeId2 = astrolabeFileId;
            }
          "
        ></SelectBar>

        <!-- <div class="select__show__item" @click="showSelectFriendsDialog = true">
          <div v-if="select_B" class="select__show__item__text">
            {{ select_B.Name }}
          </div>
          <div v-else class="select__show__item__text">請選擇</div>
          <img src="@/assets/icon_downward.svg" />
        </div> -->
      </div>
      <div class="select__addSynastrys">
        <div class="select__addSynastrys__button" @click="addSetHandler()">
          <img src="@/assets/金色加.svg" alt="" />
        </div>
        <span>新增合盤</span>
      </div>
    </div>
    <!-- 設定合盤組合 -->
    <div class="buyDBL__main">
      <h3>訂單總計</h3>
      <div class="total">
        <div class="total__info">
          <div class="total__info__item">
            合盤類型 <span>{{ typeSelect }}</span>
          </div>
          <div class="total__info__item">
            合盤組數 <span>{{ buySet.length }}組</span>
          </div>
        </div>
        <div class="total__price">{{ 300 * buySet.length }}</div>
      </div>
    </div>

    <!-- 前往結帳按鈕 -->
    <div class="nextBtn" :class="{ notReady: !verify }" @click="next()">
      前往付款
    </div>

    <!-- 好友列表dialog -->
    <SelectFriendsDialog
      v-if="showSelectFriendsDialog"
      :friendsList="List_all"
      @toSelect="({ item, is_checked }) => selectHandler(item, is_checked)"
      @cancel="showSelectFriendsDialog = false"
    ></SelectFriendsDialog>

    <!-- 新增好友dialog -->
    <CreateFriendDialog
      v-if="showCreateFriendDialog"
      @success="createFriendSuccess"
      @cancel="createFriednCancel"
    ></CreateFriendDialog>
  </div>
</template>

<script>
import FriendCardDBL from "../../components/雙人合盤/FriendCardDBL.vue";
import SelectFriendsDialog from "../../components/雙人合盤/SelectFriendsDialog.vue";
import CreateFriendDialog from "../../components/彈窗/FriendCreateDialog.vue";
import SelectBar from "../../components/SelectBar.vue";
export default {
  components: {
    FriendCardDBL,
    SelectFriendsDialog,
    CreateFriendDialog,
    SelectBar,
  },
  data() {
    return {
      List_all: [],
      List_filter: [],
      filterKey: "",
      // typeSelect: {
      //   couple: false,
      //   parentChild: false,
      //   workplace: false,
      //   friend: false,
      // },
      typeSelect: null,
      showSelectFriendsDialog: false,
      showCreateFriendDialog: false,
      buySet: [
        {
          AstrolabeId1: "",
          AstrolabeId2: "",
        },
      ], //合盤設定列表
    };
  },
  async created() {
    await this.init();
  },
  computed: {
    selectFriendsList() {
      return this.List_all.map((item) => {
        return {
          label: item.Name,
          value: item.AstrolabeFileId,
        };
      });
    },
    verify() {
      return (
        this.typeSelect &&
        this.buySet.every((item) => {
          return item.AstrolabeId1 && item.AstrolabeId2;
        })
      );
    },
  },
  methods: {
    async init() {
      try {
        let data = await this.$API.Get_MemberInfo();
        window.localStorage["loginIndex"] = data.Data.MemberId;
        this.$store.commit("set_userData", data.Data);
      } catch (e) {
        console.error("[ADD DBL] Get_MemberInfo error", e);
      }
      this.List_all = this.$store.state.userData.AstrolabeFriendLists.map(
        (item) => {
          item.is_checked = false;
          return JSON.parse(JSON.stringify(item));
        }
      ).sort((a, b) => {
        return a.ProfilePhotoSrc.split("/")[3].localeCompare(
          b.ProfilePhotoSrc.split("/")[3]
        );
      });
      this.List_filter = [...this.List_all];
    },
    filterHandler() {
      this.List_filter = this.List_all.filter(
        (item) =>
          item.Name.toUpperCase().indexOf(this.filterKey.toUpperCase()) > -1
      );
    },
    // selectHandler(item, is_checked) {
    //   if (!is_checked) {
    //     if (!this.select_A) {
    //       this.select_A = item;
    //       item.is_checked = true;
    //     } else if (!this.select_B) {
    //       this.select_B = item;
    //       item.is_checked = true;
    //     }
    //   } else {
    //     //目前已經是選取中的狀態
    //     if (this.select_A == item) {
    //       item.is_checked = false;
    //       this.select_A = null;
    //     } else if (this.select_B == item) {
    //       item.is_checked = false;
    //       this.select_B = null;
    //     }
    //   }
    //   if (this.select_A && this.select_B) {
    //     this.showSelectFriendsDialog = false;
    //   }
    //   this.$forceUpdate();
    // },
    next() {
      // let orderObj = this.$store.state.orderCreater;
      // orderObj.ProductID = this.productInfoSynastry.ProductID;
      // orderObj.Code = this.productInfoSynastry.Code;
      // orderObj.SynastryDto = {
      //   AstrolabeFileId1: this.select_A.AstrolabeFileId,
      //   AstrolabeFileId2: this.select_B.AstrolabeFileId,
      //   Relationships: [],
      // };
      // orderObj.Quantity = 0;
      // console.log("orderObj", orderObj);
      // this.$store.commit("updateOrderCreater", orderObj);
      // this.$router.push("/order/checkInfo/");
    },

    /* 新增好友相關 Start */
    addFriend() {
      this.showCreateFriendDialog = true;
    },
    createFriendSuccess() {
      this.showCreateFriendDialog = false;
      this.init();
    },
    createFriednCancel() {
      this.showCreateFriendDialog = false;
    },
    /* 新增好友相關 End */
    addSetHandler() {
      this.buySet.push({
        AstrolabeId1: "",
        AstrolabeId2: "",
      });
    },
  },
  watch: {
    $route(to) {
      if (!to.query.mode) {
        this.$store.commit("setLoading", true);
        setTimeout(() => {
          this.init();
          this.$store.commit("setLoading", false);
        }, 1000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixin.scss";
@import "@/scss/toast.scss";
@import "@/scss/dialog.scss";
.wrap#buyDBL {
  padding-top: 62px;
  width: 100%;
  max-width: 1160px;
  margin: 0 auto 88px;
  min-height: 100vh;
  letter-spacing: 0.08em;
  @include pad {
    padding: 20px 23px 0;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include pad {
      flex-direction: column;
      align-items: center;
    }
    h2.st_title {
      font-weight: 500;
      font-size: 40px;
      color: $theme-color-2;
      display: flex;
      align-items: center;
      margin-right: auto;
      line-height: 1;
      @include pad {
        display: none;
      }
      img {
        width: 54px;
        margin-right: 16px;
        @include pad {
          width: 30px;
          margin-right: 5px;
        }
      }
    }
    &__addFriendBtn {
      @include btn(40px, 40px, $theme-color-2, #fff);
      border-radius: 50%;
      margin-left: 30px;
      @include pad {
        margin-left: 20px;
      }
      img {
        width: 90%;
      }
    }
  }
  //主要設定面板
  .buyDBL__main {
    max-width: 602px;
    background: #f0f2f4;
    box-shadow: -4px -4px 16px #ffffff, -4px -4px 10px #ffffff,
      4px 4px 25px rgba(0, 0, 0, 0.15);
    border-radius: 100px;
    padding: 60px 87px;
    margin: 60px auto 40px;
    @include mobile {
      padding: 0;
      margin: 36px auto 72px;
      box-shadow: none;
    }
    h3 {
      font-size: 24px;
      line-height: 36px;
      letter-spacing: 0.08em;
      text-align: left;
      font-weight: 700;
      color: #17445c;
      @include mobile {
        font-size: 20px;
        line-height: 36px;
      }
    }
    h4 {
      font-size: 16px;
      line-height: 28px;
      text-align: left;
      color: #9b9b9b;
      margin-top: 4px;
      @include mobile {
        font-size: 14px;
        line-height: 28px;
        margin-top: 0;
      }
    }
    .buyDBL__main__typeGroup {
      margin-top: 40px;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 16px;
      line-height: 1;
      @include mobile {
        margin-top: 20px;
        gap: 13px;
      }
      div {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &__item {
        width: 95px;
        height: 134px;
        border-radius: 30px;
        box-shadow: -1.13px -1.13px 4.5px #ffffff, -1.13px -1.13px 4.5px #ffffff,
          2.25px 2.25px 6.75px rgba(0, 0, 0, 0.2);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        cursor: pointer;
        user-select: none;
        transition: 0.3s;
        @include mobile {
          width: 72px;
          height: 102px;
        }
        &.active {
          box-shadow: 2px 2px 4px #ffffff, inset 2px 2px 4px rgba(0, 0, 0, 0.15);
        }
        .checkbox-wrap {
          display: none; //隱藏checkbox
        }
        &__pic {
          width: 48px;
          margin-bottom: 12px;
          img {
            width: 48px;
          }
        }
      }
      &__title {
        font-size: 20px;
        color: #767676;
        @include mobile {
          font-size: 14px;
        }
      }
    }
    .buyDBL__main__divi {
      font-size: 16px;
      line-height: 28px;
      margin: 0 auto;
      text-align: center;
      color: #868686;
      width: 100%;
      // background-color: #f00;
      position: relative;
      display: flex;
      align-items: center;
      &__content {
        width: 135px;
        @include pad {
          font-size: 12px;
        }
      }
      &__line {
        flex-grow: 1;
        height: 3px;
        background-color: #f0f2f4;
        border-radius: 3px;
        box-shadow: 3px 3px 4px #ffffff, inset 4px 4px 10px rgba(0, 0, 0, 0.08);
      }
    }
    .select__show {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 28px;
      margin: 16px auto 40px;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: 0.06em;
      color: #767676;
      @include mobile {
        gap: 4px;
        margin: 16px auto 20px;
        font-size: 12px;
      }
      &__item {
        height: 40px;
        width: 136px;
        border-radius: 50px;
        padding: 0 8px;

        box-shadow: 2px 2px 4px #ffffff, inset 2px 2px 4px rgba(0, 0, 0, 0.15);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-size: 18px;
        line-height: 20px;
        color: #17445c;
        @include mobile {
          font-size: 14px;
        }
        &__text {
          width: calc(100% - 16px - 24px);
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          text-overflow: ellipsis;
          text-align: center;
          @include mobile {
            width: calc(100% - 16px - 20px);
          }
        }
        img {
          width: 24px;
          height: 24px;
          @include mobile {
            height: 20px;
            width: 20px;
          }
        }
      }
      &__pic {
        margin: 0 20px;
        width: 56px;
      }
    }
    .select__addSynastrys {
      display: flex;
      align-items: center;
      gap: 15px;
      font-size: 16px;
      color: #9b9b9b;
      &__button {
        width: 28px;
        height: 28px;
        border-radius: 8.75px;
        background: #f0f2f4;
        box-shadow: -1px -1px 4px #ffffff, -1px -1px 4px #ffffff,
          2px 2px 6px rgba(0, 0, 0, 0.25);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        img {
          width: 20px;
        }
      }
    }
  }

  .buyDBLFriends {
    max-width: 1032px;
    margin: 0 auto;
    h2 {
      font-weight: 500;
      font-size: 28px;
      line-height: 58px;
      color: $theme-color-2;
      display: flex;
      align-items: center;
      margin-right: auto;
      @include pad {
        font-size: 16px;
      }
    }
    &__list {
      margin-top: 36px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      @include pad {
        justify-content: center;
      }
      &__item {
        width: 480px;
        margin-bottom: 60px;
        @include pad {
          margin-bottom: 36px;
        }
      }
    }
  }
}
.checkbox-wrap {
  margin-left: 20px;
  cursor: pointer;
  font-size: 20px;
  line-height: 1.5;
  letter-spacing: 1.6px;
  color: $secondary-color-3;
  position: relative;
  padding-left: 32px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* Hide the browser's default checkbox */
  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: -2px;
    transform: translateY(-50%);
    @include rect-s(20px, 20px, $theme-color-3, 3px);
    border: 1px solid #aaa;
    box-shadow: -1px -1px 4px #ffffff, 2px 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 5.5px;
      top: 1px;
      width: 7px;
      height: 13px;
      border: solid #fff;
      border-width: 0px 3px 3px 0;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
  input:checked ~ .checkmark {
    background-color: $theme-color-1;
    border: 1px solid $theme-color-1;
    &:after {
      display: block;
    }
  }

  @include pad {
    font-size: 14px;
    line-height: 1;
    letter-spacing: 0.56px;
    margin-left: 16px;
    padding-left: 28px;
    .checkmark {
      width: 16px;
      height: 16px;
      top: 0px;
      &:after {
        top: 0.5px;
        left: 4.5px;
        width: 6px;
        height: 10px;
        border-width: 0px 2px 2px 0;
      }
    }
  }
}
.nextBtn {
  @include btn-default(250px, 56px);
  margin: 40px auto 0;
  @include mobile {
    margin: 36px auto 0;
    @include btn-default(100%, 56px);
  }
}
</style>
