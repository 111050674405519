var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap", attrs: { id: "buyDBL" } },
    [
      _c("div", { staticClass: "header" }, [
        _vm._m(0),
        _c(
          "div",
          {
            staticClass: "header__addFriendBtn hidden-pad-down",
            on: {
              click: function($event) {
                return _vm.addFriend()
              }
            }
          },
          [
            _c("img", {
              attrs: {
                src: require("../../assets/占星小鋪/add friend.svg"),
                alt: ""
              }
            })
          ]
        )
      ]),
      _c("div", { staticClass: "buyDBL__main" }, [
        _c("h3", [_vm._v("選擇合盤類型")]),
        _c("h4", [_vm._v("每次購買只能買一種合盤類型喔")]),
        _c("div", { staticClass: "buyDBL__main__typeGroup" }, [
          _c(
            "label",
            {
              staticClass: "buyDBL__main__typeGroup__item",
              class: { active: _vm.typeSelect == "couple" }
            },
            [
              _c("div", { staticClass: "checkbox-wrap" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.typeSelect,
                      expression: "typeSelect"
                    }
                  ],
                  attrs: { type: "radio", value: "couple", name: "typeSelect" },
                  domProps: { checked: _vm._q(_vm.typeSelect, "couple") },
                  on: {
                    change: function($event) {
                      _vm.typeSelect = "couple"
                    }
                  }
                }),
                _c("span", { staticClass: "checkmark" })
              ]),
              _vm._m(1),
              _c("div", { staticClass: "buyDBL__main__typeGroup__title" }, [
                _vm._v("感情")
              ])
            ]
          ),
          _c(
            "label",
            {
              staticClass: "buyDBL__main__typeGroup__item",
              class: { active: _vm.typeSelect == "friend" }
            },
            [
              _c("div", { staticClass: "checkbox-wrap" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.typeSelect,
                      expression: "typeSelect"
                    }
                  ],
                  attrs: { type: "radio", value: "friend", name: "typeSelect" },
                  domProps: { checked: _vm._q(_vm.typeSelect, "friend") },
                  on: {
                    change: function($event) {
                      _vm.typeSelect = "friend"
                    }
                  }
                }),
                _c("span", { staticClass: "checkmark" })
              ]),
              _vm._m(2),
              _c("div", { staticClass: "buyDBL__main__typeGroup__title" }, [
                _vm._v("朋友")
              ])
            ]
          ),
          _c(
            "label",
            {
              staticClass: "buyDBL__main__typeGroup__item",
              class: { active: _vm.typeSelect == "workplace" }
            },
            [
              _c("div", { staticClass: "checkbox-wrap" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.typeSelect,
                      expression: "typeSelect"
                    }
                  ],
                  attrs: {
                    type: "radio",
                    value: "workplace",
                    name: "typeSelect"
                  },
                  domProps: { checked: _vm._q(_vm.typeSelect, "workplace") },
                  on: {
                    change: function($event) {
                      _vm.typeSelect = "workplace"
                    }
                  }
                }),
                _c("span", { staticClass: "checkmark" })
              ]),
              _vm._m(3),
              _c("div", { staticClass: "buyDBL__main__typeGroup__title" }, [
                _vm._v("職場")
              ])
            ]
          ),
          _c(
            "label",
            {
              staticClass: "buyDBL__main__typeGroup__item",
              class: { active: _vm.typeSelect == "parentChild" }
            },
            [
              _c("div", { staticClass: "checkbox-wrap" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.typeSelect,
                      expression: "typeSelect"
                    }
                  ],
                  attrs: {
                    type: "radio",
                    value: "parentChild",
                    name: "typeSelect"
                  },
                  domProps: { checked: _vm._q(_vm.typeSelect, "parentChild") },
                  on: {
                    change: function($event) {
                      _vm.typeSelect = "parentChild"
                    }
                  }
                }),
                _c("span", { staticClass: "checkmark" })
              ]),
              _vm._m(4),
              _c("div", { staticClass: "buyDBL__main__typeGroup__title" }, [
                _vm._v("親子")
              ])
            ]
          )
        ])
      ]),
      _c(
        "div",
        { staticClass: "buyDBL__main" },
        [
          _c("h3", [_vm._v("設定合盤組合")]),
          _c("h4", [_vm._v("您可以一次購買多個合盤組合喔")]),
          _vm._l(_vm.buySet, function(set, index) {
            return _c(
              "div",
              { key: index, staticClass: "select__show" },
              [
                _c("SelectBar", {
                  attrs: {
                    items: _vm.selectFriendsList,
                    value: set.AstrolabeId1
                  },
                  on: {
                    change: function(astrolabeFileId) {
                      set.AstrolabeId1 = astrolabeFileId
                    }
                  }
                }),
                _vm._m(5, true),
                _c("SelectBar", {
                  attrs: {
                    items: _vm.selectFriendsList,
                    value: set.AstrolabeId2
                  },
                  on: {
                    change: function(astrolabeFileId) {
                      set.AstrolabeId2 = astrolabeFileId
                    }
                  }
                })
              ],
              1
            )
          }),
          _c("div", { staticClass: "select__addSynastrys" }, [
            _c(
              "div",
              {
                staticClass: "select__addSynastrys__button",
                on: {
                  click: function($event) {
                    return _vm.addSetHandler()
                  }
                }
              },
              [
                _c("img", {
                  attrs: { src: require("@/assets/金色加.svg"), alt: "" }
                })
              ]
            ),
            _c("span", [_vm._v("新增合盤")])
          ])
        ],
        2
      ),
      _c("div", { staticClass: "buyDBL__main" }, [
        _c("h3", [_vm._v("訂單總計")]),
        _c("div", { staticClass: "total" }, [
          _c("div", { staticClass: "total__info" }, [
            _c("div", { staticClass: "total__info__item" }, [
              _vm._v("\n          合盤類型 "),
              _c("span", [_vm._v(_vm._s(_vm.typeSelect))])
            ]),
            _c("div", { staticClass: "total__info__item" }, [
              _vm._v("\n          合盤組數 "),
              _c("span", [_vm._v(_vm._s(_vm.buySet.length) + "組")])
            ])
          ]),
          _c("div", { staticClass: "total__price" }, [
            _vm._v(_vm._s(300 * _vm.buySet.length))
          ])
        ])
      ]),
      _c(
        "div",
        {
          staticClass: "nextBtn",
          class: { notReady: !_vm.verify },
          on: {
            click: function($event) {
              return _vm.next()
            }
          }
        },
        [_vm._v("\n    前往付款\n  ")]
      ),
      _vm.showSelectFriendsDialog
        ? _c("SelectFriendsDialog", {
            attrs: { friendsList: _vm.List_all },
            on: {
              toSelect: function(ref) {
                var item = ref.item
                var is_checked = ref.is_checked

                return _vm.selectHandler(item, is_checked)
              },
              cancel: function($event) {
                _vm.showSelectFriendsDialog = false
              }
            }
          })
        : _vm._e(),
      _vm.showCreateFriendDialog
        ? _c("CreateFriendDialog", {
            on: {
              success: _vm.createFriendSuccess,
              cancel: _vm.createFriednCancel
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", { staticClass: "st_title" }, [
      _c("img", {
        attrs: { src: require("@/assets/占星小鋪/friend.svg"), alt: "" }
      }),
      _vm._v("\n      合盤設定\n    ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "buyDBL__main__typeGroup__item__pic" }, [
      _c("img", {
        attrs: { src: require("@/assets/雙人合盤/情侶.svg"), alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "buyDBL__main__typeGroup__item__pic" }, [
      _c("img", {
        attrs: { src: require("@/assets/雙人合盤/好友.svg"), alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "buyDBL__main__typeGroup__item__pic" }, [
      _c("img", {
        attrs: { src: require("@/assets/雙人合盤/職場.svg"), alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "buyDBL__main__typeGroup__item__pic" }, [
      _c("img", {
        attrs: { src: require("@/assets/雙人合盤/親子.svg"), alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "select__show__pic" }, [
      _c("img", {
        attrs: { src: require("@/assets/雙人合盤/好友分隔.svg"), alt: "" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }